export const GROUP_PRINT_QUALITY = 'print_quality'
export const GROUP_ONLINE_QUALITY = 'online_quality'
export const GROUP_SUBSCRIPTIONS = 'subscriptions'
export const GROUP_SOCIAL_ENGAGEMENT = 'social_engagement'
export const GROUP_VIDEO_QUALITY = 'video_quality'
export const GROUP_ARTICLE_QUANTITY = 'article_quantity'
export const GROUP_VIDEO_QUANTITY = 'video_quantity'
export const GROUP_NATIVE_QUANTITY = 'native_quantity'

export const AVAILABLE_SCALE_GROUPS = [
  {
    id: GROUP_PRINT_QUALITY,
    title: 'Kvalita PRINT'
  },
  {
    id: GROUP_ONLINE_QUALITY,
    title: 'Kvalita ONLINE'
  },
  {
    id: GROUP_SUBSCRIPTIONS,
    title: 'Subscriptions'
  },
  {
    id: GROUP_SOCIAL_ENGAGEMENT,
    title: 'Social engagement'
  },
  {
    id: GROUP_VIDEO_QUALITY,
    title: 'Video kvalita'
  },
  {
    id: GROUP_ARTICLE_QUANTITY,
    title: 'Kvantita PRINT/ONLINE článkov'
  },
  {
    id: GROUP_VIDEO_QUANTITY,
    title: 'Kvantita VIDEO'
  },
  {
    id: GROUP_NATIVE_QUANTITY,
    title: 'Native kvantita'
  }
]
