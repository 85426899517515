// print quality
const SCALE_QUALITY_A = 'quality_a'
const SCALE_QUALITY_B = 'quality_b'
const SCALE_QUALITY_C = 'quality_c'
// online quality
const SCALE_PAGE_VIEWS = 'page_views'
const SCALE_PAGE_ENGAGEMENT = 'page_engagement'
// subscriptions
const SCALE_PAID_PV = 'paid_pv'
const SCALE_CONVERSIONS = 'conversions'
// social engagement
const SCALE_DISQUS_COMMENTS = 'disqus_comments'
const SCALE_FB_SHARES = 'fb_shares'
// video quality
const SCALE_VIDEO_VIEWS = 'video_views'
const SCALE_AVG_PLAYTHROUGH = 'avg_playthrough'
// print/online article quantity
const SCALE_P1_AUTHOR = 'p1_author'
const SCALE_P1_AGENCY = 'p1_agency'
const SCALE_P2_AUTHOR = 'p2_author'
const SCALE_P2_AGENCY = 'p2_agency'
const SCALE_ONLINE_AUTHOR = 'online_author'
const SCALE_ONLINE_FROM_PRINT = 'online_from_printu'
const SCALE_ONLINE_AGENCY = 'online_agency'
const SCALE_QUIZZES_ARTICLES = 'quizzes_articles'
const SCALE_PHOTOSTORY_ARTICLES = 'photostory_articles'
// video quantity
const SCALE_AUTHOR = 'author'
const SCALE_SLIDESHOW = 'slideshow'
const SCALE_SOCIAL = 'social'
const SCALE_AGENCY = 'agency'
// native quantity
const SCALE_PRINT = 'print'
const SCALE_ONLINE = 'online'
const SCALE_VIDEO_AUTHOR = 'video_author'

export const AVAILABLE_SCALE_NAMES = [
  {
    id: SCALE_QUALITY_A,
    title: 'A'
  },
  {
    id: SCALE_QUALITY_B,
    title: 'B'
  },
  {
    id: SCALE_QUALITY_C,
    title: 'C'
  },
  {
    id: SCALE_PAGE_VIEWS,
    title: 'PV'
  },
  {
    id: SCALE_PAGE_ENGAGEMENT,
    title: 'Page engagement'
  },
  {
    id: SCALE_PAID_PV,
    title: 'Paid PV'
  },
  {
    id: SCALE_CONVERSIONS,
    title: 'Conversions'
  },
  {
    id: SCALE_DISQUS_COMMENTS,
    title: 'Disqus comments'
  },
  {
    id: SCALE_FB_SHARES,
    title: 'FB shares'
  },
  {
    id: SCALE_VIDEO_VIEWS,
    title: 'Video views'
  },
  {
    id: SCALE_AVG_PLAYTHROUGH,
    title: 'Avg. playthrough'
  },
  {
    id: SCALE_P1_AUTHOR,
    title: 'P1 (autorský)'
  },
  {
    id: SCALE_P1_AGENCY,
    title: 'P2 (agentúrny)'
  },
  {
    id: SCALE_P2_AUTHOR,
    title: 'P2 (autorský)'
  },
  {
    id: SCALE_P2_AGENCY,
    title: 'P2 (agentúrny)'
  },
  {
    id: SCALE_ONLINE_AUTHOR,
    title: 'Online autorský'
  },
  {
    id: SCALE_ONLINE_FROM_PRINT,
    title: 'Online z printu'
  },
  {
    id: SCALE_ONLINE_AGENCY,
    title: 'Online agentúrny'
  },
  {
    id: SCALE_QUIZZES_ARTICLES,
    title: 'Quiz články'
  },
  {
    id: SCALE_PHOTOSTORY_ARTICLES,
    title: 'Fotostory články'
  },
  {
    id: SCALE_AUTHOR,
    title: 'Autorské'
  },
  {
    id: SCALE_SLIDESHOW,
    title: 'Slideshow'
  },
  {
    id: SCALE_SOCIAL,
    title: 'Social'
  },
  {
    id: SCALE_AGENCY,
    title: 'Agentúrne'
  },
  {
    id: SCALE_PRINT,
    title: 'Print'
  },
  {
    id: SCALE_ONLINE,
    title: 'Online'
  },
  {
    id: SCALE_VIDEO_AUTHOR,
    title: 'Video autorské'
  }
]
