<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterScales">
          <div class="row">
            <div class="col-lg-2">
              <app-select
                v-model="filter.siteName"
                :options="sites"
                :label="$t('scale.site')"
                option-value="name"
                id="filter_site"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.scaleGroup"
                :options="availableScaleGroups"
                :label="$t('scale.scaleGroup')"
                id="filter_scaleGroup"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.name"
                :options="availableScaleNames"
                :label="$t('scale.name')"
                id="filter_name"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <app-input v-model="filter.id" id="filter_id" :label="$t('scale.id')"></app-input>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success m-t-30">
                {{ $t('scale.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import ScaleFilter from '../../model/ScaleFilter'
import { AVAILABLE_SCALE_GROUPS } from '../../model/ValueObject/ScaleGroup'
import { AVAILABLE_SCALE_NAMES } from '../../model/ValueObject/ScaleName'

export default {
  name: 'ScaleFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(ScaleFilter),
      availableScaleGroups: AVAILABLE_SCALE_GROUPS,
      availableScaleNames: AVAILABLE_SCALE_NAMES
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/all']
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterScales () {
      this.$store.commit('scale/setPage', 1)
      this.$store.commit('scale/setFilter', this.filter)
      this.$store.dispatch('scale/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(ScaleFilter)
      this.$store.commit('scale/setFilter', this.filter)
      this.$store.dispatch('scale/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['scale/filter']
  }
}
</script>

<style lang="scss">

</style>
